<template>
  <div id="dashboard-sidebar">
    <ul class="mt-4">
      <!-- Menu General -->
      <router-link to="/user/home">
        <li class="p-3">
          <a class="d-flex align-items-center">
            <img
              src="@/assets/dashboard/icon/sidebar/House.svg"
              class="mr-3"
              alt="icon"
            />
            Beranda
          </a>
        </li>
      </router-link>

      <!-- Menu penerbit dan pencetak -->
      <span v-if="roleName == 'Penerbit' || roleName == 'Pencetak'">
        <router-link to="/user/profil/publisher">
          <li class="p-3">
            <a class="d-flex align-items-center">
              <img
                src="@/assets/dashboard/icon/sidebar/User.svg"
                class="mr-3"
                alt="icon"
              />
              Profil
            </a>
          </li>
        </router-link>
        <router-link to="/user/portfolio">
          <li class="p-3">
            <a class="d-flex align-items-center">
              <img
                src="@/assets/dashboard/icon/sidebar/FileText.svg"
                class="mr-3"
                alt="icon"
              />
              Portofolio
            </a>
          </li>
        </router-link>
        <router-link to="/user/penilaian">
          <li class="p-3">
            <a class="d-flex align-items-center">
              <img
                src="@/assets/dashboard/icon/sidebar/Book.svg"
                class="mr-3"
                alt="icon"
              />
              Buku yang Dinilai
            </a>
          </li>
        </router-link>
        <router-link to="/user/pembinaan">
          <li class="p-3">
            <a class="d-flex align-items-center">
              <img
                src="@/assets/dashboard/icon/sidebar/ChalkboardTeacher.svg"
                class="mr-3"
                alt="icon"
              />
              Pembinaan
            </a>
          </li>
        </router-link>
        <router-link to="/user/agenda-penilaian">
          <li class="p-3">
            <a class="d-flex align-items-center">
              <img
                src="@/assets/dashboard/icon/sidebar/PencilSimpleLine.svg"
                class="mr-3"
                alt="icon"
              />
              Agenda Penilaian
            </a>
          </li>
        </router-link>
        <router-link to="/user/izin-cetak">
          <li class="p-3">
            <a class="d-flex align-items-center">
              <img
                src="@/assets/dashboard/icon/sidebar/Printer.svg"
                class="mr-3"
                alt="icon"
              />
              Izin Cetak Buku
            </a>
          </li>
        </router-link>
      </span>
      <span v-else>
        <router-link to="/user/portfolio">
          <li class="p-3">
            <a class="d-flex align-items-center">
              <img
                src="@/assets/dashboard/icon/sidebar/FileText.svg"
                class="mr-3"
                alt="icon"
              />
              Portofolio
            </a>
          </li>
        </router-link>
        <router-link to="/user/profil">
          <li class="p-3">
            <a class="d-flex align-items-center">
              <img
                src="@/assets/dashboard/icon/sidebar/User.svg"
                class="mr-3"
                alt="icon"
              />
              Profil
            </a>
          </li>
        </router-link>
        <router-link to="/user/tugas">
          <li class="p-3">
            <a class="d-flex align-items-center">
              <img
                src="@/assets/dashboard/icon/sidebar/PencilSimpleLine.svg"
                class="mr-3"
                alt="icon"
              />
              Tugas Workshop
            </a>
          </li>
        </router-link>
        <router-link to="/user/pembinaan">
          <li class="p-3">
            <a class="d-flex align-items-center">
              <img
                src="@/assets/dashboard/icon/sidebar/ChalkboardTeacher.svg"
                class="mr-3"
                alt="icon"
              />
              Pembinaan
            </a>
          </li>
        </router-link>
      </span>

      <!-- Menu Satker -->
      <span v-if="roleName == 'Satker'">
        <router-link to="/user/pengajuan-isbn">
          <li class="p-3">
            <a class="d-flex align-items-center">
              <img
                src="@/assets/dashboard/icon/sidebar/ChalkboardTeacher.svg"
                class="mr-3"
                alt="icon"
              />
              Pengajuan ISBN
            </a>
          </li>
        </router-link>
      </span>

      <!-- Menu Penerbit & NGO -->
      <span v-if="roleName == 'NGO'">
        <router-link to="/user/izin-cetak">
          <li class="p-3">
            <a class="d-flex align-items-center">
              <img
                src="@/assets/dashboard/icon/sidebar/Printer.svg"
                class="mr-3"
                alt="icon"
              />
              Izin Cetak Buku
            </a>
          </li>
        </router-link>
        <router-link to="/user/agenda-penilaian">
          <li class="p-3">
            <a class="d-flex align-items-center">
              <img
                src="@/assets/dashboard/icon/sidebar/PencilSimpleLine.svg"
                class="mr-3"
                alt="icon"
              />
              Agenda Penilaian
            </a>
          </li>
        </router-link>
        <router-link to="/user/penilaian">
          <li class="p-3">
            <a class="d-flex align-items-center">
              <img
                src="@/assets/dashboard/icon/sidebar/Book.svg"
                class="mr-3"
                alt="icon"
              />
              Buku yang Dinilai
            </a>
          </li>
        </router-link>
      </span>

      <!-- Menu Pencetak -->
      <!-- <span v-if="roleName == 'Pencetak'">
        <li class="p-3">
          <router-link to="/user/izin-cetak">
            <a><i class="fa fa-print fa-fw"></i> Izin Cetak Buku</a>
          </router-link>
        </li>
      </span> -->
    </ul>

    <!--<div v-if="roleName == 'Penerbit'" class="alert alert-warning">
      Terdapat kesulitan dalam pengisian formulir?
      <a href="https://chat.whatsapp.com/G7019VOuCuWDm6VnZoRG35" target="_blank">Tanyakan di sini <i class="fab fa-whatsapp"></i></a>
    </div>-->

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Penilaian Buku</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              Silahkan lengkapi data profil penerbit terlebih dahulu, terutama
              berkas-berkas untuk mendaftar penilaian buku.
            </p>
            <router-link to="/user/profil/publisher">
              <a type="button" data-dismiss="modal"
                ><i class="fas fa-fw fa-edit"></i> Lengkapi profil</a
              >
            </router-link>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "../store";
export default {
  name: "Sidebar",
  data() {
    return {
      user_id: "",
      email: "",
      username: "",
      hash: "",
      roleName: "",
      showDaftarBuku: false,
      checkRole: false,
    };
  },
  created() {
    var user = localStorage.getItem("user");
    var parse = JSON.parse(user);

    this.user_id = parse.user_id;
    this.username = parse.fullname;
    this.hash = parse.hash;
    this.email = parse.email;
    this.roleName = parse.role_name;

    // if (this.roleName == "Penerbit" || this.roleName == "Pencetak") {
    //   this.checkRole = true;
    // }

    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}api/user/getPublisherProfile`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        if (res?.data?.result?.siup != "") {
          if (this.roleName == "Penerbit") {
            this.showDaftarBuku = true;
          }
        } else {
          this.showDaftarBuku = false;
        }
      })
      .catch((err) => console.log(err));
  },
};
</script>

<style scoped>
.router-link-active li {
  background-color: #eef4ff;
  border-radius: 8px;
}
.router-link-active li a {
  color: #072ea2 !important;
}
li:hover {
  background-color: #eef4ff;
  border-radius: 8px;
}
a:hover {
  text-decoration: none;
}
</style>
